
import Http from './Http'

export default {
    async getMatches() {
        return await Http.get(`/football/matches`)
    },
    async createMatchPreviewText(match_id, data) {
        return await Http.post(`/football/match/${match_id}/previewText`, data)
    },
    async getSportTeams(data) {
        return await Http.get(`/football/teams?${data}`)
    },
    async updateSportTeam(id, data) {
        return await Http.patch(`/football/sport_team/${id}`, data)
    },
    async updateWatchStatus(team_id , data){
        return await Http.post(`/football/sport_team/${team_id}/updateWatchStatus`, data)
    } ,
    async addRelatedTeam(data){
        return await Http.post(`/football/sport_team/addRelatedTeam`, data)
    } ,
    async getRelatedTeams(data) {
        return await Http.get(`/football/teams/getRelatedTeams?${data}`)
    },
    async removeRelatedTeams(team_id){
        return await Http.delete(`/football/teams/removeRelatedTeams/${team_id}`)
    },
    async getTeamSquad(team_code,team_id) {
        return await Http.get(`/football/squad?type=${team_code}&team_id=${team_id}`)
    },

}


